import React from 'react'
import Layout from '../../components/layout'
import SEO from '../../components/seo'
import * as PropTypes from 'prop-types'

const ScratchPage = () => {
  const VideoList = () => {
    return <ul>
      <Video title='Flüssige Bewegung' videoId="7BEee0NLbtE"/>
      <Video title='Realistisches Springen' videoId="q9_2biy3wpY"/>
      <Video title='Auf Sachen drauf springen' videoId="TVP9QUXJSrk"/>
      <Video title='Wie man ein Raumschiff zum Schießen bekommt, Klone und mehr'
             videoId="KLMeBf8cRQI"/>
      <Video title='Highscore und Cloud Variablen' videoId="jABt3jb01B8"/>
      <Video title='Wie ihr coole Bilder für eure Projekte findet'
             videoId="JCwXya68pbA"/>
      <Video title='Vokabeltrainer in Scratch3 bauen' videoId="F0iLDpVuemo"/>
      <Video title='Sprachausgabe' videoId="05AcSdLVGds"/>
      <Video title='Musik und Geräusche' videoId="LftF58Getr4"/>
      <Video title='Chatbot bauen mit MachineLearningForKids.co.uk'
             videoId="lolMHyxGKKA"/>
      <Video title='Sprachsteuerung mit MachineLearningForKids'
             videoId="8tdhfq0KvxA"/>
      <Video title='Bewegter Hintergrund (infinite Scroll)'
             videoId="JnGoyET0BjU"/>
      <Video title='Tower Defence Spiel'
             videoId="191ZLZ8aORQ"/>
      <Video title='Tetris Teil 1'
             videoId="xQcK95_v3uE"/>
    </ul>
  }
  const Video = ({title, videoId}) => {
    return <li className="videoLink"><h3>{title}</h3>
      <iframe width="560" height="315"
              src={`https://www.youtube.com/embed/${videoId}`}
              allow="autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen frameBorder="0"/>
    </li>
  }
  Video.propTypes = {
    title: PropTypes.string,
    videoId: PropTypes.string,
  }


  return <Layout>
    <SEO title="Scratch-Videos" keywords={[]}/>
    <h1>Scratch-Videos</h1>
    <p><a href="https://scratch.mit.edu/">Scratch</a> ist eine
      Programmierumgebung, mit der Kinder spielerisch programmieren lernen
      können. Ich habe an der Schule meiner Kinder einen Kurs zum Programmieren
      mit Scratch gegeben, als Teil des Ganztagsprogramms. Die Dritt- und
      Viertklässler haben eigene Spiele und Animationen gebaut und dabei viel
      Spaß gehabt.</p>
    <p>Während des Kurses sind viele Fragen immer wieder aufgekommen, deshalb
      habe ich mich daran gemacht, die auch für andere zu beantworten und dafür
      zusammen mit meinem Sohn ein paar&nbsp;
      <a
        href="https://www.youtube.com/channel/UCS0bOXo4GEk0M6xkab1FVMA">Tutorial-Videos</a> aufgenommen,
      in denen wir häufig vorkommende Probleme in Scratch zeigen und wie man sie
      löst.</p>
    <VideoList/>
  </Layout>
}


export default ScratchPage
